.cardaccordion {
  margin-bottom: 0;
  border-radius: 0;
  padding: 0 10px 0 10px;
  border: none;
  box-shadow: unset;
}
.cardaccordion:not(:last-child) {
  border-bottom: 2px solid #06c !important;
}
.cardaccordion .card-header {
  background: transparent;
  border-radius: 0;
  padding: 0;
  border: none;
}
.accordion button{display:inline-block;font-weight:400;color:#212529;text-align:center;vertical-align:middle;-webkit-user-select:none;user-select:none;background-color:transparent;border:1px solid transparent;padding:0.375rem 0.75rem;font-size:1rem;line-height:1.5;border-radius:0.25rem;transition:color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;}
@media (prefers-reduced-motion: reduce){
    .accordion button{transition:none;}
}

.accordion .accordion__button{
    width: 100%;
}
.accordion button:hover{color:#212529;text-decoration:none;}
.cardaccordion .card-header button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 22px 70px 22px 0;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  line-height: 1.4em;
  border: none;
  border-radius: 0;
  margin: 0;
  color: var(--heading);
  text-decoration: none;
  position: relative;
}
.cardaccordion .card-header button:before {
  content: "";
  font-family: "font-awesome";
  position: absolute;
  font-size: 28px;
  right: 0;
  left: auto;
  top: 30%;
  transition: 0.3s ease-in-out;
}
.accordion .cardaccordion .card-header button:before {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  top: 37px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg);
}
.cardaccordion .card-header .accordion__button[aria-expanded="true"] button::before{transform:rotate(-135deg);}
@media (max-width: 991px) {
  .accordion-style-two .card .card-header button {
    font-size: 20px;
  }
}



















.accordion-style-two .card .card-body{padding:0 70px 30px 0;}
.accordion-style-two .card .card-body p{font-size:20px;line-height:1.55em;}
.accordion .card .card-body{animation:fade-in-up 0.2s;}
@media (max-width: 991px){
.accordion-style-two .card .card-body p{font-size:18px;}
.accordion-style-two .card .card-body{padding:0 30px 30px 0;}
}
/*! CSS Used keyframes */
@keyframes fade-in-up{from{transform:translate3d(0, 40px, 0);}to{transform:translate3d(0, 0, 0);opacity:1;}}
