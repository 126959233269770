
.team-img{
    width: 150px;
    height: 150px;
}
@media (max-width: 576px){
    .team-items h3,.team-items p{
        margin-bottom: 5px;
    }
    .team-items p{
        font-size: 12px;
    }
    .team-img{
        width: 100px;
        height: 100px;
    }
}
