.quotetitle{
    font-weight: 400;
}
@media only screen and (max-width: 533px) {
    .quotetitle{
        font-weight: 300;
    }
}

.counterwrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .counterright {
    padding-left: 5px;
    font-size: 30px;
    background-clip: text;
    background-image: linear-gradient(180deg, #ffb6ff, #b344ff);
    display: inline-block;
    color: transparent;
  }
  
  .counter {
    animation: none;
    font-size: 45px;
    background-clip: text;
    background-image: linear-gradient(180deg, #ffb6ff, #b344ff);
    display: inline-block;
    color: transparent;
  }