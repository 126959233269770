.quoteimg{
    width: 24px;
    height: 24px;
    margin: 12px 0;
}
@media only screen and (max-width: 533px) {
    .quoteimg{
        width: 14px;
        height: 14px;
        margin: 10px 0;
    }
}
.text-muted {
    color: #9aa0a6 !important;
}

.blockquoteitemcard{
    margin-bottom: 20px;
    padding: 0 10px;
}
.blockquoteitemcard:not(:last-child){
    border-bottom: 2px solid #06c!important;
}
.blockquoteitemcard-body{
    padding: 10px;
}
