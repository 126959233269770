.swiper-pagination-bullet {
    background: #fff !important;
    opacity: 0.5 !important;
    border-radius: 0 !important;
    height: 4px !important;
    width: 30px !important;
    box-shadow: inset 0 0 0 2px #fff;
  }
  .swiper-pagination-bullet:hover {
    opacity: 0.6 !important;
  }
  .swiper-pagination-bullet-active {
    opacity: 1 !important;
  }
  .swiper-pagination-bullet-active:hover {
    opacity: 1 !important;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #fff !important;
    width: 78px !important;
    height: 78px !important;
    line-height: 78px;
    background: transparent;
    border-radius: 50%;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: #2250fc;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 20px !important;
    font-weight: 700 !important;
  }
  @media only screen and (max-width: 600px) {
    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 15px !important;
    }
    .swiper-button-next,
    .swiper-button-prev {
      width: 58px !important;
      height: 58px !important;
      line-height: 58px;
    }
    .swiper-pagination-bullet {
      height: 3px !important;
      width: 20px !important;
    }
  }
  .xx {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 20%;
    animation: movekenburns 12s linear;
    width: 100%;
    height: 100vh;
    position: absolute;
  }

  .xx2 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 20%;
    width: 100%;
    height: 100vh;
    position: absolute;
  }

  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @keyframes movekenburns {
    0% {
      transform: scale(1);
      -ms-transform: scale(1);
      /* IE 9 */
  
      -webkit-transform: scale(1);
      /* Safari and Chrome */
  
      -o-transform: scale(1);
      /* Opera */
  
      -moz-transform: scale(1);
      /* Firefox */
    }
    100% {
      transform: scale(1.2);
      -ms-transform: scale(1.2);
      /* IE 9 */
  
      -webkit-transform: scale(1.2);
      /* Safari and Chrome */
  
      -o-transform: scale(1.2);
      /* Opera */
  
      -moz-transform: scale(1.2);
      /* Firefox */
    }
  }
  
  .slider-title {
    font-size: 55px;
    line-height: 1.1;
    font-weight: 300;
    letter-spacing: 0.024em;
    color: #fff;
  }
  .slider-subtitle {
    margin-top: 20px;
    font-size: 20px;
    line-height: 1.1;
    font-weight: 400;
    color: #fff;
  }
  .slider-content {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .slider-image {
    overflow: hidden;
  }
  .slider-container-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slider-containerflexbox {
    padding-top: 5%;
    width: 70%;
    margin: auto;
    justify-content: center;
  }
  .slider-containerflexbox img {
    width: 40px !important;
  }
  .slider-containerflexbox p {
    color: #fff;
    font-size: 16px;
    line-height: 1.1;
    font-weight: 400;
  }

  .btn-reveal-left {
    margin: 30px;
  }
  @media only screen and (max-width: 992px) {
    .slider-title {
      font-size: 42px;
      letter-spacing: 0.014em;
    }
    .slider-subtitle {
      margin-top: 15px;
      font-size: 16px;
    }
    .slider-containerflexbox{
      width: 90%;
    }
    .slider-containerflexbox p {
      font-size: 14px;
    }
    .slider-containerflexbox img {
      width: 30px !important;
    }
    .btn-reveal-left {
      margin: 15px;
    }
  }
  @media only screen and (max-width: 533px) {
    .slider-title {
      font-size: 28px;
      letter-spacing: 0.004em;
    }
    .slider-subtitle {
      margin-top: 10px;
      font-size: 13px;
    }
    .slider-containerflexbox{
      width: 100%;
    }
    .slider-containerflexbox p {
      font-size: 11px;
    }
    .slider-containerflexbox img {
      width: 25px !important;
    }
    .btn-reveal-left {
      margin: 10px;
    }
  }
  
  
  /* CSS */
  .button-learn-more-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }
  .button-learn-more {
    position: relative;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 200px;
    padding: 6px 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    border-radius: 6px;
    border: none;
  
    color: #fff;
    background: linear-gradient(180deg, #06c 0%, #005CCC 100%);
     background-origin: border-box;
    box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  @media only screen and (max-width: 992px) {
    .button-learn-more-wrapper{
      padding: 30px 0;
    }
    .button-learn-more {
     font-size: 13px;
    }
  }
  @media only screen and (max-width: 533px) {
    .button-learn-more-wrapper{
      padding: 40px 0;
    }
    .button-learn-more {
      font-size: 12px;
     }
    
  }
  .button-learn-more:hover{
    color: rgba(255, 255, 255, 0.8) !important;
  }
  
  .button-learn-more:focus {
    box-shadow: inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2), 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), 0px 0px 0px 3.5px rgba(58, 108, 217, 0.5);
    outline: 0;
  }
  .bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    z-index: 0;
    background: rgba(0, 0, 0, 0.59);
    opacity: .5;
}
  
  
  
  